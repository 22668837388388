import { keyframes } from '@emotion/css';
import styled from '@emotion/styled';
import { isEmpty } from 'lodash';
import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { NextRouter, useRouter } from 'next/router';
import { memo, useEffect, useMemo, useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { FaRegEye } from 'react-icons/fa';
import { FiPlayCircle } from 'react-icons/fi';
import { IMAGE_SIZE } from 'src/components/cards/image';
import { APP_IMG, DEFAULT_COUNT_VIEW } from 'src/constants';
import { IDataLayerGlobal } from 'src/interface/data-layer';
import { IArticle } from 'src/interface/section';
import { useCountviews } from 'src/utils/countview';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { convertBadge, numeralFormat, splitRote, timestamp, useDatePublished } from 'src/utils/helper';

const Image = dynamic(import('src/components/cards/image'));

type Props = {
  className?: string;
  data?: IArticle | any;
  showBadge?: boolean;
  showTitle?: boolean;
  showBlurb?: boolean;
  showByline?: boolean;
  showCenter?: boolean;
  showPublishDate?: boolean;
  showCountView?: boolean;
  showReadmore?: boolean;
  showTimeAgo?: boolean;
  showFooter?: boolean;
  eventDataLayer?: IDataLayerGlobal;
  target?: string;
  external?: boolean;
  path?: string;
  size?: any;
};
const CardV: NextPage<Props> = ({
  className = '',
  data,
  showBadge = true,
  showTitle = true,
  showBlurb = true,
  showByline = false,
  showCenter = false,
  showPublishDate = true,
  showReadmore = false,
  showCountView = true,
  showFooter = true,
  eventDataLayer = {},
  target = '',
  external = false,
  path = '',
  size
}) => {
  if (isEmpty(data)) return null;
  const [countView, setCountView] = useState<number>(0);
  const router: NextRouter = useRouter();

  const conditionCountview: boolean = showCountView && countView > DEFAULT_COUNT_VIEW;
  const conditionBadge: string | false = showBadge && convertBadge(data, false);

  useEffect(() => {
    (async () => {
      try {
        const _pageviews: number = await useCountviews(data);
        setCountView(_pageviews);
      } catch (err: any) {
        console.error(`${timestamp()} ==========> CARD_FULL ERROR :`, err.message);
      }
    })();
  }, [data]);
  const href = useMemo(() => {
    return `${splitRote(router)}${data?.link || path}`;
  }, [data, path, router]);

  return (
    <CardWrapper
      className={`${className} card-v`} // ! DATALAYER
      onClick={() => sendDataLayerGlobal({ ...eventDataLayer })}
    >
      <a
        role='link'
        href={href}
        aria-label={showPublishDate ? `${useDatePublished(data?.published_at)} ${data?.title}` : data?.title}
        target={`${target === 'blank' ? '_blank' : '_self'}`}
        rel='noopener noreferrer'
        title={data?.title}
        tabIndex={-1}
      >
        {/* ---------------------------------- IMAGE ---------------------------------  */}
        <div className='card-image'>
          <Image image={data?.image || '/cache/default.webp'} title={data?.alt_image ?? data?.title} external={external} size={size || IMAGE_SIZE.MD_WEBP} />

          {data.is_video === true && (
            <div className='play-btn'>
              <FiPlayCircle size={80} />
            </div>
          )}
          {!!conditionBadge && (
            <div className='badge'>
              <small className='line-clamp --1'>{conditionBadge}</small>
            </div>
          )}
        </div>
        <div className='card-detail'>
          {/* ---------------------------------- CONTENT -------------------------------  */}
          <div className='card-content'>
            {showCenter && (
              <div className='center-content'>
                {showByline && (
                  <>
                    {data.byline && (
                      <p className='byline'>
                        <AiOutlineUser /> {data.byline}
                      </p>
                    )}
                  </>
                )}
                {showPublishDate && (
                  <>
                    {data?.published_at && (
                      <div className='published-date'>
                        <small>{useDatePublished(data?.published_at)}</small>
                      </div>
                    )}
                  </>
                )}
                {conditionCountview && (
                  <div className='count-view'>
                    <FaRegEye size={12} />
                    <small>{numeralFormat(countView)}</small>
                  </div>
                )}
              </div>
            )}
            {data?.id === 'skeleton' && (
              <div className='skeleton-title'>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            {showTitle && <h3 className='title line-clamp --3'>{data?.title}</h3>}
            {showBlurb && <small className='blurb line-clamp --3'>{data?.blurb || ''}</small>}
          </div>

          {/* ------------------------------ CONTENT-FOOTER ----------------------------  */}
          {showFooter && (
            <div className='card-footer'>
              <div className='footer-right'>
                {showReadmore && (
                  <div className='readmore'>
                    <small>อ่านทั้งหมด</small>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </a>
    </CardWrapper>
  );
};
const rotate = keyframes`
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(1000%, 0);
  }
`;
const CardWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  &.skeleton-loading {
    .title {
      display: none;
    }
    .skeleton-title {
      border-radius: 50px;
      & > div {
        position: relative;
        overflow: hidden;
        height: 15px;
        margin: 5px 0;
        border-radius: 50px;
        background-color: var(--background-color);
        width: 100%;
        &:nth-child(2) {
          width: 70%;
          &:before {
            content: '';
            position: absolute;
            background-color: var(--background-color);
            height: 15px;
            width: 30px;
            transform: rotate(116deg);
            filter: blur(3px);
            animation: ${rotate} 3s linear infinite;
          }
        }
        &:nth-child(3) {
          width: 30%;
          &:before {
            content: '';
            position: absolute;
            background-color: var(--background-color);
            height: 15px;
            width: 30px;
            transform: rotate(116deg);
            filter: blur(3px);
            animation: ${rotate} 3.5s linear infinite;
          }
        }
        &:before {
          content: '';
          position: absolute;
          background-color: var(--background-color);
          height: 15px;
          width: 30px;
          transform: rotate(116deg);
          filter: blur(3px);
          animation: ${rotate} 2s linear infinite;
        }
      }
    }
  }
  &.background-transparent {
    .card-detail {
      background-color: transparent;
    }
  }
  /* ---------------------------------- HOVER --------------------------------- */
  &:hover {
    .card-image {
      img {
        transform: scale(1.1);
      }
      .badge small {
        &::before {
          transform-origin: left;
          transform: scaleX(1);
        }
      }
    }
    .card-content {
      .title {
        background-position: 0;
      }
    }
  }
  .card-image {
    position: relative;
    padding-top: 56.5%;
    background-image: url(${APP_IMG}/images/default-pic.webp);
    background-size: cover;
    background-repeat: repeat;
    overflow: hidden;
    background-position: center;

    .resolution-image {
      box-shadow: 0px 0px 2px 0px var(--background-color);
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      top: 0;
      left: 0;
    }
    /* ---------------------------------- IMAGE --------------------------------- */
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: center;
      aspect-ratio: 16/9;
      transform-origin: 50% 65%;
      transition: transform 2s, filter 0.5s ease-out;
      /* cls */
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    
      svg {
        color: var(--white-color);
        filter: drop-shadow(0px 4px 6px var(--black-color));
      }
    }
    /* ---------------------------------- BADGE --------------------------------- */
    .badge {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      background-color: var(--tertiary-color);
      max-width: max-content;
      small {
        position: relative;
        line-height: 1;
        color: var(--black-color);
        font-weight: 300;
        font-size: 12px;
        text-decoration: none;
        text-transform: capitalize;
        /* animation */
        transition: All 0.2s ease-in;
        padding: 0;
        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 0px;
          background-color: var(--tertiary-color);
          bottom: 0;
          left: 0;
          transform-origin: left;
          transform: scaleX(0);
          transition: transform 0.3s ease-in-out;
        }
        /* animation */
      }
    }
  }
  .card-detail {
    position: relative;
    background-color: var(--white-color);
    /* border: 1px solid var(--border-color); */
    padding: 8px;
  }
  /* -------------------------------- CONTENTS -------------------------------- */
  .card-content {
    .center-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 10px;

      p.byline {
        display: flex;
        align-items: center;
        color: var(--tertiary-color);
        font-size: 14px;
        font-weight: 500;

        &::after {
          content: '/';
          margin-left: 5px;
          color: var(--border-color);
          @media (max-width: 690px) {
            content: '';
          }
          @media (max-width: 475px) {
            content: '/';
          }
        }
        svg {
          margin-right: 5px;
          color: var(--tertiary-color);
        }
      }
    }
  }
  /* ---------------------------------- TITLE --------------------------------- */

  .title {
    position: relative;
    min-height: 48px;
    text-transform: capitalize;
    font-size: 18px;
    line-height: 1.65;
    font-weight: 500;
    color: var(--black-color);
    background-image: linear-gradient(to right, var(--tertiary-color), var(--tertiary-color) 50%, rgb(0 0 0 / 95%) 50%);
    background-size: 200% 100%;
    background-position: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
    @media (max-width: 767px) {
      min-height: 35px;
      font-size: 18px;
    }
  }
  /* ---------------------------------- BLURB --------------------------------- */
  .blurb {
    margin: 5px 0;
    text-transform: capitalize;
    font-size: 14px;
    line-height: 1.65;
    font-weight: 300;

    color: var(--black-color);
    opacity: 0.65;
    @media (min-width: 767px) {
      font-size: 16px;
      min-height: 82px;
    }
    @media (max-width: 767px) {
      margin: 2.5px 0;
    }
  }
  /* --------------------------------- FOOTER --------------------------------- */
  .card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: auto;
    padding: 5px 0 10px;
    color: var(--grey-color);
    opacity: 0.85;
  }
  small {
    text-transform: lowercase;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.65;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
`;
export default memo(CardV);
