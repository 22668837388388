import styled from '@emotion/styled';
import _ from 'lodash';
import Link from 'next/link';
import CardH from 'src/components/cards/card-h';
import { IMAGE_SIZE } from 'src/components/cards/image';
import { sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

type Props = { id: string; heading: string; link?: string; data: any; image?: string; color?: string; eventDataLayer?: any; external?: boolean; externalLink?: string | null; child?: any; path?: string, isHref?: boolean };

const SectionEvent = ({ id, data, heading, link, image, color, eventDataLayer, external = false, externalLink = null, child = null, path, isHref = true }: Props) => {

  return (
    <>
      <EventWrapper id={id} color={color} image={image}>
        <div className='grid-section container'>
          {(!_.isEmpty(link) && isHref) ? (
            <a href={`${link}`}
              target='_self'
              aria-label={heading}
              rel='noreferrer'
              // ! DATALAYER
              onClick={() => sendDataLayerGlobal({
                ...eventDataLayer,
                data: {
                  ...eventDataLayer.data,
                  block: '1',
                  title: heading,
                  index: 1
                }
              })}
              className='block-1'>
              <div>
                {child}
              </div>
            </a>) : (<div className='block-1'>
              {child}
            </div>)}
          <div className='block-2'>
            {data.slice(0, 3).map(
              (item: any, i: number) =>
                <CardH
                  key={i}
                  data={item}
                  showFooter={false}
                  externalLink={externalLink}
                  external={external}
                  size={IMAGE_SIZE.MD_WEBP}
                  showBadge={false}
                  showPublishDate={true}
                  showBlurb={false}
                  path={path}
                  target={external ? 'blank' : ''}
                  // ! DATALAYER
                  eventDataLayer={{
                    ...eventDataLayer,
                    data: {
                      ...eventDataLayer.data,
                      title: item.title,
                      index: i + 1
                    }
                  }}
                />
            )}
          </div>
          <Link href={`${link}`} passHref>
            <a
              target={`${external ? '_blank' : '_self'}`}
              rel={`${external ? 'nofollow noopener noreferrer' : ''}`}
              className='readmore'
              // ! DATALAYER
              onClick={() =>
                sendDataLayerGlobal({
                  ...eventDataLayer,
                  data: {
                    ...eventDataLayer.data,
                    heading: heading
                  }
                })
              }
            >
              <small>อ่านทั้งหมด</small>
            </a>
          </Link>
        </div>
      </EventWrapper>
    </>
  );
};
const getColor = (props: any) => props.color;
const getImage = (props: any) => props.image;

const EventWrapper: any = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  max-width: 100%;

  .grid-section {
    background-color: ${getColor};
    /* border-top-right-radius: 38px; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'block-1 block-1 block-2' 'block-1 block-1 readmore';
    grid-gap: 15px;
    padding: 0;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'block-1'
        'block-2'
        'readmore';
      padding-bottom: 15px;
      .block-1 {
        padding-top: 56.5%;
        /* border-top-right-radius: 38px; */
      }
    }
    .block-1 {
      grid-area: block-1;
      background-repeat: none;
      background-position: left center;
      background-size: cover;
      background-image: url(${getImage});
      background-color: grey;
      min-height: 485px;
      padding: 0;
      /* .card-h .card-image {
        max-width: 200px;
      } */

      .card-h {
        top: 145px;
        /* left: 35px; */
        /* max-width: 780px; */
        padding: 0 35px 0px 55px;
        .card-h-href {
          grid-template-columns: 1fr 0.5fr 0.5fr 1fr;
          grid-template-areas:
            'card-image card-image card-image card-detail'
            'card-footer card-footer card-footer card-detail';
        }
        @media (max-width: 768px) {
          top: 170px;
          padding: 0 15px 0px 35px;
          .card-h-href {
            grid-template-columns: 1fr 0.2fr 0.5fr 1fr;
          }
        }
        @media (max-width: 480px) {
          top: 75px;
          .card-h-href {
            grid-template-columns: 0.2fr 0.5fr 0.5fr 1fr;
          }
          .blurb {
            display: none;
          }
        }
      }
      .card-h .card-detail {
        border: none;
      }

      .card-h .title,
      .published-date small {
        color: var(--tertiary-color);
      }
      .card-h .blurb {
        color: var(--white-color);
      }
      @media (max-width: 480px) {
        min-height: 220px;
      }
    }
    .block-2 {
      grid-area: block-2;
      padding: 15px 15px 0 15px;
      .card-h {
        max-width: 600px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 15px;
        :last-child {
          border: none;
          margin: 0;
          padding: 0;
        }
        .title {
          color: var(--white-color);
        }
        .published-date small {
          color: #ffe4c8;
          opacity: 0.95;
        }
        .card-detail {
          border: none;
        }
      }
    }
    .readmore {
      grid-area: readmore;
      margin-bottom: 10px;
      align-items: flex-end;
    }
  }

  .background-event {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: cover;
  }
  &#section-american, &#section-climate-center, &#section-health-wealth {
    .readmore small{
      color: var(--white-color) !important
    }
  }

`;
export default SectionEvent;
